
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";
import * as WorkshopFormsService from "@/services/WorkshopFormsService";
import WorkshopFormData from "@/components/WorkshopFormData.vue";

@Component({
    components: {
        WorkshopFormData: () => import('@/components/WorkshopFormData.vue')
    }
})
export default class WorkshopFormsDetails extends Vue {

    form = null as any;
    loadingRefresh = false;
    otherForm = null as any;
    otherFormText = '';
    thisFormText = '';
    loading = false;

    async mounted() {
        this.loading = true;
        this.form = await WorkshopFormsService.GetForm(this.$route.params.id);
        const otherForms = await WorkshopFormsService.ListForms() as any[];

        if (otherForms.find(e => e.id === this.$route.params.id)) {
            // if (ante) in this.form.name
            if (this.form.name.includes("(ante)")) {
                const otherFormName = this.form.name.replace("(ante)", "(post)");
                const otherForm = otherForms.find(e => e.name === otherFormName);
                if (otherForm) {
                    this.otherForm = await WorkshopFormsService.GetForm(otherForm.id);
                    console.log(this.otherForm);
                }
                this.otherFormText = 'Post';
                this.thisFormText = 'Ante';
            }
        }
        this.loading = false;
    }

    async refresh() {
        this.loadingRefresh = true;
        this.form = await WorkshopFormsService.RefreshForm(this.$route.params.id);
        this.loadingRefresh = false;
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: 'success',
            text: 'Formulier vernieuwd'
        });
    }

    getOtherFormResponses(question: string) {
        if (this.otherForm) {
            const matchingQuestion = this.otherForm.questions.find((e: any) => e.title === question);
            if (matchingQuestion) {
                return matchingQuestion.responses;
            }
        }
        return null;
    }
}


